import React, { useCallback, useMemo } from "react";

import MaterialStepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import type { SxProps, Theme } from "@mui/material/styles";
import type { StepIconProps } from "@mui/material/StepIcon";

import { colors } from "@puzzle/theme";

import { Check, CircledIcon, Warning } from "@puzzle/icons";

import { Text } from "../Text/Text";
import { Box } from "@puzzle/ve";

const stepLabelClasses = {
  active: "Mui-active",
  completed: "Mui-completed",
  disabled: "Mui-disabled",
  label: "MuiStepLabel-label",
};

const LabelColors = {
  active: colors.gray200,
  completed: colors.gray200,
  disabled: colors.gray500,
  error: colors.red300,
};

const getStepIcon = (props: StepIconProps, step: number) => {
  const { active, completed, error } = props;

  if (error) {
    return <Warning size={24} fill={colors.red500}></Warning>;
  }
  if (active) {
    return (
      <CircledIcon backgroundColor={colors.greenalpha}>
        <Text weight={"bold"} color={"black"}>
          {step}
        </Text>
      </CircledIcon>
    );
  }
  if (completed) {
    return (
      <CircledIcon backgroundColor={colors.greenalpha}>
        <Check stroke={colors.black} />
      </CircledIcon>
    );
  }
  // Disabled state
  return (
    <CircledIcon>
      <Text color={"gray300"}>{step}</Text>
    </CircledIcon>
  );
};

const CONNECTOR_DIST_TO_TOP = "12px";
const WIDTH_FOR_CONNECTOR = "calc(50% - 20px)";

type StepperProps = {
  steps: string[];
  activeStep: number;
  orientation: "horizontal" | "vertical";
  isStepFailed: (index: number) => boolean;
  alternativeLabel?: boolean;
  getStepContent?: (index: number) => React.ReactNode;
  css?: React.CSSProperties;
};

export const Stepper = ({
  steps,
  activeStep,
  orientation = "horizontal",
  isStepFailed,
  alternativeLabel = true,
  getStepContent,
  css,
}: StepperProps) => {
  const stepLabelStyles = useMemo(() => {
    return {
      [`.${stepLabelClasses.active}.${stepLabelClasses.label}`]: {
        color: LabelColors.active,
      },
      [`.${stepLabelClasses.completed}.${stepLabelClasses.label}`]: {
        color: LabelColors.completed,
      },
      [`.${stepLabelClasses.disabled}.${stepLabelClasses.label}`]: {
        color: LabelColors.disabled,
      },
      [`.${stepLabelClasses.label}`]: {
        color: colors.white,
      },
    };
  }, []);

  // Fixes overflow issues with step connector when using alternative labels
  // original connector assumes all steps are the same width
  const getStepConnectorStyles = useCallback((): SxProps<Theme> => {
    if (!alternativeLabel) return {};
    return {
      ".MuiStep-alternativeLabel": {
        "& .MuiStepConnector-root": {
          display: "none",
        },
        "&.MuiStep-root::before": {
          top: CONNECTOR_DIST_TO_TOP,
          position: "absolute",
          backgroundColor: colors.white,
          height: "1px",
          width: WIDTH_FOR_CONNECTOR,
          content: '""',
          left: "0",
        },
        "&.MuiStep-root::after": {
          top: CONNECTOR_DIST_TO_TOP,
          position: "absolute",
          backgroundColor: colors.white,
          height: "1px",
          width: WIDTH_FOR_CONNECTOR,
          content: '""',
          right: "0",
        },
        "&.MuiStep-root:last-of-type::after": {
          display: "none",
        },
        "&.MuiStep-root:first-of-type::before": {
          display: "none",
        },
      },
    };
  }, [alternativeLabel]);

  return (
    <Box css={{ width: "100%" }}>
      <MaterialStepper
        activeStep={activeStep}
        alternativeLabel={alternativeLabel}
        orientation={orientation}
        sx={getStepConnectorStyles()}
        style={css}
      >
        {steps.map((label, index) => {
          const labelProps: {
            optional?: React.ReactNode;
            error?: boolean;
          } = {};
          if (isStepFailed(index)) {
            labelProps.error = true;
          }

          return (
            <Step key={label}>
              <StepLabel
                {...labelProps}
                StepIconComponent={(props) => getStepIcon(props, index + 1)}
                sx={stepLabelStyles}
              >
                <Box
                  css={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {(getStepContent && getStepContent(index)) ?? label}
                </Box>
              </StepLabel>
            </Step>
          );
        })}
      </MaterialStepper>
    </Box>
  );
};
