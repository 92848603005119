import React from "react";

import { textRecipe, TextVariants } from "./text.css";

export type TextProps = TextVariants;

/**
 * Intentionally simple Text component, which enforces variants through VE's recipe system.
 * To add sizing or colours, add them to the recipe.
 */
export const Text = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & TextProps
>(({ variant, weight, color, ...props }, ref) => {
  const variantClass = textRecipe({ variant, weight, color }); // Is a useMemo worthwhile here?

  return <span className={variantClass} {...props} ref={ref} />;
});

Text.displayName = "Text";
