import React from "react";
import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export default function Warning({
  viewBox = "0 0 14 14",
  size = 12,
  fill = colors.red500,
  ...iconProps
}: IconProps) {
  return (
    <Icon size={size} {...iconProps} viewBox={viewBox}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12944 2.04028L1.34334 10.508C0.966549 11.1746 1.44816 12 2.2139 12H11.7861C12.5518 12 13.0335 11.1746 12.6567 10.508L7.87056 2.04028C7.48777 1.36302 6.51223 1.36302 6.12944 2.04028ZM7 3.25005C7.41421 3.25005 7.75 3.58584 7.75 4.00005V7.00005C7.75 7.41426 7.41421 7.75005 7 7.75005C6.58579 7.75005 6.25 7.41426 6.25 7.00005V4.00005C6.25 3.58584 6.58579 3.25005 7 3.25005ZM7 11C7.55228 11 8 10.5523 8 10C8 9.44776 7.55228 9.00005 7 9.00005C6.44772 9.00005 6 9.44776 6 10C6 10.5523 6.44772 11 7 11Z"
        fill={fill}
      />
    </Icon>
  );
}
